import { Media, spacing } from '@renderbus/common/theme'
import styled from 'styled-components'
import sign from './icons/sign.svg'
import Banner from './raytools-banner/banner.png'
import BannerMb from './raytools-banner/banner-mb.png'

export const RenderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
  height: 1080px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    height: 100%;
  }
`
export const BackgroundBanner = styled.div`
  position: absolute;
  width: 100%;
  height: 1080px;
  background: ${`url(${Banner})`} no-repeat;
  background-size: 100% 100%;
  background-color: #181818;
  z-index: -1;
  ${Media.lessThan(Media.small)} {
    position: relative;
    background: ${`url(${BannerMb})`} no-repeat;
    background-size: 100% 100%;
    height: 670px;
  }
`
export const ChestIcon = styled.div`
  img {
    margin-top: 60px;
    margin-right: 100px;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const ChestIconMb = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    img {
      width: 300px;
    }
    position: absolute;
    top: 370px;
    left: 50%;
    margin-left: -150px;
  }
`
export const ChestIntroduction = styled.div`
  padding-top: 176px;
  ${Media.lessThan(Media.small)} {
    position: absolute;
    top: 82px;
    height: 100%;
    padding: 19px 18px 32px;
  }
`
export const IntroductionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  ${Media.lessThan(Media.small)} {
    align-items: top;
  }
`
export const IntroductionSubTitle = styled.h1`
  width: 307px;
  height: 56px;
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 41px;
  margin: 10px 10px 10px 0;
  ${Media.lessThan(Media.small)} {
    font-size: 29px;
    width: 154px;
    height: 100%;
    margin: 0;
  }
`
export const LittleTitle = styled.div`
  width: 57px;
  height: 31px;
  border: 1px solid #ff8b00;
  border-radius: 4px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ff8b00;
  display: flex;
  align-items: center;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    width: 42px;
    height: 20px;
    margin-top: 8px;
  }
`
export const IntroductionTip = styled.div`
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin: 12px 0 ${spacing.large};
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }
`

export const IntroductionContent = styled.div`
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #13cb75;
  line-height: 2px;
  margin-top: 11px;
  ${Media.lessThan(Media.small)} {
    font-size: 14px;
    height: 100%;
    line-height: 19px;
  }
`
export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 10px;
    > p {
      width: 100%;
      padding: 0 4px;
      margin: 0;
    }
  }
`

export const ContentIcon = styled.div`
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-image: url(${sign});
  ${Media.lessThan(Media.small)} {
    width: 12px;
    height: 12px;
    margin-right: 0;
    margin-top: -20px;
  }
`
