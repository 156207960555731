import React from 'react'
import { Wrapper, Button } from '@renderbus/common/components'
import { Media } from '@renderbus/common/theme'

import styled from 'styled-components'
import { color } from '@renderbus/common/theme'
import { isTest } from '@renderbus/common/service'

const BannerContainer = styled.div`
  padding: 70px 0 44px;
  text-align: center;
  background: ${color.primary};
  color: white;
  ${Media.lessThan(Media.small)} {
    padding: 47px 0 42px;
  }
`
const BannerTitle = styled.h2`
  margin: 0;
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  + a {
    width: 160px;
    border-radius: 40px;
    font-size: 16px;
    line-height: 40px;
    vertical-align: top;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 14px;
    padding-bottom: 13px;
  }
`
class TreasureChestBanner extends React.PureComponent {
  render() {
    return (
      <BannerContainer>
        <Wrapper>
          <BannerTitle>新用户注册，免费领取无门槛云渲染券</BannerTitle>
          <BannerTitle>畅享专业可靠的高速云渲染服务</BannerTitle>
          <Button
            as='a'
            id='raytools'
            backgroundColor='white'
            color='primary'
            rel='nofollow'
            href={
              isTest()
                ? 'https://task-pre.renderbus.com/sso/register'
                : 'https://task.renderbus.com/sso/register'
            }
            download
          >
            立即领取
          </Button>
        </Wrapper>
      </BannerContainer>
    )
  }
}

export default TreasureChestBanner
