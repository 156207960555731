import React from 'react'
import { Button, Wrapper, SEO } from '@renderbus/common/components'
import Layout from '../molecules/layout'
import TreasureChestBanner from '../molecules/treasure-chest-banner'
import Instroduction from '../molecules/treausre-chest-instroduction'
import {
  TreasureContent,
  TabContentWrapper,
  ChestTitle,
  InstallContent,
  InstallWrapper,
  InstallText,
  InstallTitle,
  InstallSubText,
  InstallImg,
  FetureContent,
  FunctionTitle,
  FunctionTab,
  TabWrapper,
  TipWrapper,
  CotWrapper,
  CotSubWrapper,
  CotContent,
  CotTitle,
  CotSubTitle,
  SubContent,
  ButtonSubWrapper,
  TabMainWrapper,
  TipSubWrapper,
  ArrowIcon,
  FreeDownload,
  SoftwareInfo,
  SoftwareInfoItem,
  RightLine,
  Version,
  SoftwareName,
  FileLine,
  VerticalLine,
  TipTitle,
  TipMayaWrapper,
  TipTitleGroup,
} from './raytools.atom'
import Install from '../../src/molecules/treasure-chest-install/icons/install.gif'
import Explain from '../../src/molecules/treasure-chest-install/icons/explain.gif'
import Unstall from '../../src/molecules/treasure-chest-install/icons/uninstall.gif'
import InstallMaya from '../../src/molecules/treasure-chest-install/icons/install-maya.gif'
import ExplainMaya from '../../src/molecules/treasure-chest-install/icons/explain-maya.gif'
import AddPlugin from '../../src/molecules/treasure-chest-install/icons/addplugin.gif'
import InstallC4D from '../../src/molecules/treasure-chest-install/icons/install-c4d.gif'
import ExplainC4D from '../../src/molecules/treasure-chest-install/icons/explain-c4d.gif'
import UninstallC4D from '../../src/molecules/treasure-chest-install/icons/uninstall-c4d.gif'
import VIcon from '../../src/molecules/treasure-chest-install/icons/v-icon.svg'
import TimeIcon from '../../src/molecules/treasure-chest-install/icons/time-icon.svg'
import VersionIcon from '../../src/molecules/treasure-chest-install/icons/version-icon.svg'
import DownloadIcon from '../../src/molecules/treasure-chest-install/icons/download-icon.png'
import ScenesSelectIcon from '../../src/molecules/treasure-chest-install/icons/scenes-select.svg'
import ScenesUnselectIcon from '../../src/molecules/treasure-chest-install/icons/scenes-unselect.svg'

const List01 = [
  {
    title: 'Blend转V-RayBlend',
    content:
      '在场景使用V-Ray渲染时，场景中有标准的Blend，即为不兼容材质，很容易造成渲染异常慢，甚至卡格子渲不出图等情况，使用该功能可避免该情况。',
    warning: [
      '注意：此脚本只处理Blend材质转换到VrayBlendMtl，以及其中的Standard转换为VrayMtl；',
      '此脚本只支持V-Ray渲染器时使用。',
    ],
    url: 'https://www.renderbus.com/support-new/support-id-1001/',
    showBorder: [true, true],
  },
  {
    title: 'V-Ray材质RBDF修复',
    content:
      'V-Ray材质的BRDF值在高低版本互导模型的时候，BRDF值有时会变空，导致模型材质透明不能渲染。使用该功能进行一键检查，即可检查并修复场景中所有BRDF空值。',
    url: 'https://www.renderbus.com/support-new/support-id-1002/',
    showBorder: [true, true],
  },
  {
    title: 'V-Ray材质细分调节',
    content:
      '场景渲染卡在材质上，甚至渲染到某些材质上时会崩溃，该功能可帮助用户统一修改材质细分及深度值测试。',
    url: 'https://www.renderbus.com/support-new/support-id-1003/',
    showBorder: [false, true],
  },
  {
    title: '贴图模糊修改',
    content:
      '场景材质的贴图模糊值如果存在0.01或其他小于1的值，部分场景渲染时会卡在这些材质上，有时还会导致某些场景最终渲染崩溃。使用该功能可一键修改模糊值。',
    url: 'https://www.renderbus.com/support-new/support-id-1004/',
    showBorder: [true, false],
  },
  {
    title: '',
    content: '',
    url: '',
    showBorder: [true, false],
  },
]

const List02 = [
  {
    title: 'Multiscatter检查工具',
    content:
      '使用Multiscatter植物插件,如果制作不规范,或者Multiscatter种子数量太多，有空的Multiscatter，Multiscatter拾取线段等会造成平台多机器渲染随机崩溃的概率很高,使用该功能可协助检查降低奔溃概率。',
    url: 'https://www.renderbus.com/support-new/support-id-1005/',
    showBorder: [true, true],
  },
  {
    title: '修改器塌陷',
    content:
      '不同的3ds Max小版本及高低版本之间，物体上的修改器可能会产生bug，会导致修改器签数关异,渲染效果不对,渲染随机失败，场景打开失败等情况。鉴于此，“修改器塌陷”能较大程度避免上述问题。',
    url: 'https://www.renderbus.com/support-new/support-id-1007/',
    showBorder: [true, true],
  },
  {
    title: '反面修复',
    content:
      '当场景中有模型内部分反面时，会造成某些材质渲染反面处发黑，偏暗或其它效果不对的情况，使用“反面修复”可帮助修复该问题。',
    url: 'https://www.renderbus.com/support-new/support-id-1006/',
    showBorder: [false, true],
  },
  {
    title: '选择空物体',
    content:
      '当场景中有模型面数为空时，有可能会造成渲染异常崩溃等情况。点击“选择空物体”功能即可选择到空物体。',
    url: 'https://www.renderbus.com/support-new/support-id-1008/',
    showBorder: [true, false],
  },
  {
    title: '重物体查找',
    content:
      '当场景中有重物体时，会造成重面，渲染时有可能会发生闪烁或者发黑斑的情况。点击“重物体查找”脚本功能，可选查找范围为全场景或者选择的物体中。',
    url: 'https://www.renderbus.com/support-new/support-id-1009/',
    showBorder: [true, false],
  },
  {
    title: '重面处理',
    content:
      '当场景中有重面时，渲染时有可能会发生闪烁或者发黑斑的情况。点击“重物体查找”脚本功能，可协助解决该情况。',
    url: 'https://www.renderbus.com/support-new/support-id-1010/',
    showBorder: [false, false],
  },
]

const List03 = [
  {
    title: 'V-Ray灯光细分调整',
    content:
      'V-Ray渲染卡慢，或渲染中途崩溃，可尝试统一修改灯光细分值测试。使用“V-Ray灯光细分调整”在调整面板可对VRayLight，VRaylES，VRaySun三种灯光细分边行调整。',
    url: 'https://www.renderbus.com/support-new/support-id-1011/',
    showBorder: [true, false],
  },
  {
    title: '解锁视图',
    content:
      '点击“解锁视图”，如果有视图锁定则提示“当前场景视图已解除锁定”，如果场景中原本就没有锁定视图则弹出“当前场景视图未锁定”。',
    url: 'https://www.renderbus.com/support-new/support-id-1012/',
    showBorder: [true, false],
  },
]

const List04 = [
  {
    title: '保存速度修复',
    content:
      '当场景保存异常慢时可以尝试使用，修复加快保存场景。常见于观察保存时的文件到最后几kb进文件时进度超慢。点击“保存速度修复”脚本功能即可操作。',
    url: 'https://www.renderbus.com/support-new/support-id-1013/',
    showBorder: [true, false],
  },
  {
    title: '垃圾数据清理',
    content:
      '当场景物体情况和保存大小明显异常时可以尝试使用，清理场景垃圾，比如场景只有1000面数模型,保存却有几百MB的文件，或者场景中另存一个相机出去，文件也有几百MB大小，使用该功能即可执行清理操作。',
    url: 'https://www.renderbus.com/support-new/support-id-1014/',
    showBorder: [true, false],
  },
  {
    title: '病毒清理',
    content:
      '近几年，3ds Max病毒比较猖獗，且传染率极强，很难根治，对制作者带来很大的痛苦，该功能根据目前已知的几代病毒特征，开发出一键病毒清理功能。',
    warning: '注意: 鉴于病毒传播手段恶略多端，使用该功能前后，强烈建议另存备份文件以防万一。',
    url: 'https://www.renderbus.com/support-new/support-id-1015/',
    showBorder: [false, false],
  },
]

const List11 = [
  {
    title: 'CmdRender',
    content:
      '测试Cmd渲染过程，用户可以定义渲染的帧数等相关信息，该过程可以和渲染农场渲染过程相贴近，检查是否在Cmd状态下和界面的效果有差异。',
    url: 'https://www.renderbus.com/support-new/support-id-1018/',
    showBorder: [true, true],
  },
  {
    title: 'GetInfo',
    content: '渲染问题排查工具，用于本地的渲染测试和问题排查。',
    warning: 'GetInfo可一键获取用户本地环境信息、硬件信息以及获取Maya的设置信息等。',
    url: 'https://www.renderbus.com/support-new/support-id-1019/',
    showBorder: [true, true],
  },
  {
    title: 'MD5',
    content:
      'MD5信息检查工具，文件由于制作过程中，有的资产会更新覆盖，比如tx贴图或者其他使用频繁的资产， 一个文件除了时间，大小等可以帮助判断，文件的MD5信息也是帮助判断文件是否更新的重要手段，只要文件的MD5和之前不一样了，便可以认为文件已经更新。',
    url: 'https://www.renderbus.com/support-new/support-id-1021/',
    showBorder: [false, true],
  },
  {
    title: 'PluginSubmission',
    content:
      '插件提交工具，可以直接在Maya软件中提交当前打开的场景文件，而不用像使用客户端提交一样需要将对应场景文件拖拽进入客户端界面。',
    url: 'https://www.renderbus.com/support-new/support-id-1022/',
    showBorder: [true, true],
  },
  {
    title: 'ProxyAnalyse',
    content: '代理分析工具，支持Ass和Rs的代理分析场景。',
    url: 'https://www.renderbus.com/support-new/support-id-1023/',
    showBorder: [true, true],
  },
  {
    title: 'SceneCheck',
    content:
      '场景检查工具，检查场景中的常见错误，包括渲染设置，场景结构，层级命名等等，以帮助用户发现并解决存在的隐患。',
    url: 'https://www.renderbus.com/support-new/support-id-1020/',
    showBorder: [false, true],
  },
  {
    title: 'WindowToReset',
    content:
      '制作工程中，当某个工具窗口消失了，怎么点菜单栏都看不到该工具窗口，这个时候其实是窗口在我们的屏幕外面，可以通过窗口重置工具，把窗口放置到屏幕内。',
    url: 'https://www.renderbus.com/support-new/support-id-1024/',
    showBorder: [true, false],
  },
  {
    title: '支持自定义添加',
    content: '支持添加各类自定义插件，欢迎下载体验。',
    url: '',
    showBorder: [true, false],
  },
]

const List12 = [
  {
    title: 'ObjectReplace',
    content: '模型替换工具，让模型物体之间很方便的进行更新、替代。',
    url: 'https://www.renderbus.com/support-new/support-id-1025/',
    showBorder: [true, false],
  },
  {
    title: 'ProxyDisplayMode',
    content:
      'mesh及代理显示切换，一键快捷的对场景中选中的代理文件及mesh显示模式进行切换，不用一个个单独切换，也可根据不同节点类型，分类、分次选择并切换。',
    url: 'https://www.renderbus.com/support-new/support-id-1026/',
    showBorder: [true, false],
  },
  {
    title: '支持自定义添加',
    content: '支持添加各类自定义插件，欢迎下载体验。',
    url: '',
    showBorder: [false, false],
  },
]

const List13 = [
  {
    title: '支持自定义添加',
    content: '支持添加各类自定义插件，欢迎下载体验。',
    url: '',
    showBorder: [true, false],
  },
  {
    title: '',
    content: '',
    url: '',
    showBorder: [true, false],
  },
]

const List14 = [
  {
    title: '支持自定义添加',
    content: '支持添加各类自定义插件，欢迎下载体验。',
    url: '',
    showBorder: [true, false],
  },
  {
    title: '',
    content: '',
    url: '',
    showBorder: [true, false],
  },
]
const List15 = [
  {
    title: 'ArnoldLigGrpAOV',
    content: '阿诺德灯光组工具，针对阿诺德渲染器，可以快速对灯光分灯光组。',
    url: 'https://www.renderbus.com/support-new/support-id-1027/',
    showBorder: [true, true],
  },
  {
    title: 'ColorSpaceCheck',
    content:
      'Maya文件的制作过程中会出现以下情况：不同的模型使用了同一张贴图，且同1张贴图的色彩空间，由于效果需要，可能设置的不一致，从而使后续渲染阶段，渲染器转tx或者rstexbin环节出的贴图缓存缓存，出现色彩空间和预期不一样，导致的渲染效果有差异。',
    warning: '色彩空间检查工具就是用于检查和修改此类问题。',
    url: 'https://www.renderbus.com/support-new/support-id-1028/',
    showBorder: [true, true],
  },
  {
    title: 'RsTexBinConvert',
    content:
      'Redshift渲染器不像Arnold会自动在贴图同目录生成对应的tx贴图文件。如果想要转rs的贴图，一般不太方便。Redshift转帖图缓存工具能方便的找到文件中的贴图，并转换rstexbin贴图文件。',
    url: 'https://www.renderbus.com/support-new/support-id-1029/',
    showBorder: [false, true],
  },
  {
    title: '支持自定义添加',
    content: '支持添加各类自定义插件，欢迎下载体验。',
    url: '',
    showBorder: [true, false],
  },
  {
    title: '',
    content: '',
    url: '',
    showBorder: [true, false],
  },
]

const List21 = [
  {
    title: 'RT_放置地面',
    content: '一键直接执行，可将选中的对象放置到地面。',
    url: 'https://www.renderbus.com/support-new/support-id-1031/',
    showBorder: [true, true],
  },
  {
    title: 'RT_对象实例化',
    content: '一键直接执行，将选中的对象转为实例对象(以选中的最后一个对象为主对象)。',
    url: 'https://www.renderbus.com/support-new/support-id-1031/',
    showBorder: [true, true],
  },
  {
    title: 'RT_生成圆形边',
    content: '一键直接执行，将选中的封闭边转换为圆形。',
    url: 'https://www.renderbus.com/support-new/support-id-1031/',
    showBorder: [false, true],
  },
  {
    title: 'RT_四边面封盖',
    content: '接执行，将圆柱极点封盖。',
    url: 'https://www.renderbus.com/support-new/support-id-1031/',
    showBorder: [true, true],
  },
  {
    title: 'RT_随机颜色',
    content: '一键直接执行，将选中对象的显示颜色更改为随机颜色(按下Ctrl的时只使用灰色)。',
    url: 'https://www.renderbus.com/support-new/support-id-1031/',
    showBorder: [true, true],
  },
  {
    title: 'RT_一键断面',
    content: '一键直接执行，将选中的面执行分离并删掉原模型的面。',
    url: 'https://www.renderbus.com/support-new/support-id-1031/',
    showBorder: [false, true],
  },
]

const List22 = [
  {
    title: 'RT_删除所有标签',
    content: '一键直接执行，删除选中对象的所有标签。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [true, true],
  },
  {
    title: 'RT_删除空选集',
    content: '一键直接执行，删除选中对象的所有空选集。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [true, true],
  },
  {
    title: 'RT_删除材质标签',
    content: '一键直接执行，删除选中对象的所有材质标签。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [false, true],
  },
  {
    title: 'RT_删除丢失材质标签',
    content: '一键直接执行，删除场景中所有的丢失材质的标签。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [true, true],
  },
  {
    title: 'RT_选择最下级',
    content:
      '一键直接执行，选中最下层。此功能支持的版本是Cinema 4D R23及以下的版本，R24及以上的版本不支持',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [true, true],
  },
  {
    title: 'RT_选择链接效果器',
    content: '一键直接执行，选中已选Mograph对象的效果器。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [false, true],
  },
  {
    title: 'RT_选择链接生成器',
    content: '一键直接执行，选中已选效果器的Mograph对象。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [true, true],
  },
  {
    title: 'RT_选择链接对象',
    content: '一键直接执行，选择克隆link对象。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [true, true],
  },
  {
    title: 'RT_可见(全部)',
    content: '一键直接执行，切换选中对象的编辑器和渲染可见模式。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [false, true],
  },
  {
    title: 'RT_可见(编辑视窗)',
    content: '一键直接执行，切换选中对象的编辑器可见模式。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [true, false],
  },
  {
    title: 'RT_可见(渲染器)',
    content: '一键直接执行，切换选中对象的渲染可见模式。',
    url: 'https://www.renderbus.com/support-new/support-id-1032/',
    showBorder: [true, false],
  },
]

const List23 = [
  {
    title: 'RT_FPS检查',
    content: '一键同步修改工程FPS参数和渲染FPS参数的插件。',
    url: 'https://www.renderbus.com/support-new/support-id-1033/',
    showBorder: [true, true],
  },
  {
    title: 'RT_MD5检查',
    content: '一键获取任意选中文件的MD5值。',
    url: 'https://www.renderbus.com/support-new/support-id-1034/',
    showBorder: [true, true],
  },
  {
    title: 'RT_对象点面信息',
    content: '一键列出所有可编辑对象的点面信息。',
    url: 'https://www.renderbus.com/support-new/support-id-1035/',
    showBorder: [false, true],
  },
  {
    title: 'RT_打开文件夹',
    content: '一键打开常用文件夹路径(Cinema 4D首选项、项目根目录)。',
    url: 'https://www.renderbus.com/support-new/support-id-1036/',
    showBorder: [true, false],
  },
  {
    title: 'RT_材质转换',
    content: '根据渲染器类型筛选材质，目前仅提供快速选中标准材质并将其一键转为Octane材质。',
    url: 'https://www.renderbus.com/support-new/support-id-1037/',
    showBorder: [true, false],
  },
]

const DataMap = [
  [List01, List02, List03, List04],
  [List11, List12, List13, List14, List15],
  [List21, List22, List23],
]

const versionList = [
  {
    title: '瑞兔百宝箱（For 3ds Max）',
    newVersion: 'V1.0.1',
    updateTime: '2023-07-30',
    supportVersion: '3ds Max 2012-2024',
    id: 'raytools',
    link: '/Raytool_for_3ds_Max_CN_V1.0.1.mzp',
  },
  {
    title: 'Raytool for Maya',
    newVersion: 'V1.0.0',
    updateTime: '2022-09-05',
    supportVersion: 'Maya 2015-2022',
    id: 'RaytoolForMaya',
    link: '/Raytool_2022_12_24.zip',
  },
  {
    title: 'Raytool for Cinema 4D',
    newVersion: 'V1.1.2',
    updateTime: '2024-01-08',
    supportVersion: 'Cinema 4D R21-R26，2023-2024',
    id: 'RaytoolForC4D',
    link: '/Raytool for Cinema 4D.rar',
  },
]

class TreasureChest extends React.PureComponent {
  state = {
    x: 0,
    y: 0,
    file: 0,
    selectedTag: 0,
    selectedSubTag: 0,
    openStatus: 1,
    isSelectSubTag00: true,
    isSelectSubTag01: false,
    isSelectSubTag02: false,
    isSelectSubTag03: false,
    isSelectSubTag10: true,
    isSelectSubTag11: false,
    isSelectSubTag12: false,
    isSelectSubTag13: false,
    isSelectSubTag14: false,
    isSelectSubTag20: true,
    isSelectSubTag21: false,
    isSelectSubTag22: false,
  }
  setIsSelectSubTag00() {
    this.setState((prev) => ({
      isSelectSubTag00: !prev.isSelectSubTag00,
      isSelectSubTag01: false,
      isSelectSubTag02: false,
      isSelectSubTag03: false,
    }))
  }
  setIsSelectSubTag01() {
    this.setState((prev) => ({
      isSelectSubTag00: false,
      isSelectSubTag01: !prev.isSelectSubTag01,
      isSelectSubTag02: false,
      isSelectSubTag03: false,
    }))
  }
  setIsSelectSubTag02() {
    this.setState((prev) => ({
      isSelectSubTag00: false,
      isSelectSubTag01: false,
      isSelectSubTag02: !prev.isSelectSubTag02,
      isSelectSubTag03: false,
    }))
  }
  setIsSelectSubTag03() {
    this.setState((prev) => ({
      isSelectSubTag00: false,
      isSelectSubTag01: false,
      isSelectSubTag02: false,
      isSelectSubTag03: true,
    }))
  }
  setIsSelectSubTag10() {
    this.setState((prev) => ({
      isSelectSubTag10: !prev.isSelectSubTag10,
      isSelectSubTag11: false,
      isSelectSubTag12: false,
      isSelectSubTag13: false,
      isSelectSubTag14: false,
    }))
  }
  setIsSelectSubTag11() {
    this.setState((prev) => ({
      isSelectSubTag10: false,
      isSelectSubTag11: !prev.isSelectSubTag11,
      isSelectSubTag12: false,
      isSelectSubTag13: false,
      isSelectSubTag14: false,
    }))
  }
  setIsSelectSubTag12() {
    this.setState((prev) => ({
      isSelectSubTag10: false,
      isSelectSubTag11: false,
      isSelectSubTag12: !prev.isSelectSubTag12,
      isSelectSubTag13: false,
      isSelectSubTag14: false,
    }))
  }
  setIsSelectSubTag13() {
    this.setState((prev) => ({
      isSelectSubTag10: false,
      isSelectSubTag11: false,
      isSelectSubTag12: false,
      isSelectSubTag13: !prev.isSelectSubTag13,
      isSelectSubTag14: false,
    }))
  }
  setIsSelectSubTag14() {
    this.setState((prev) => ({
      isSelectSubTag10: false,
      isSelectSubTag11: false,
      isSelectSubTag12: false,
      isSelectSubTag13: false,
      isSelectSubTag14: !prev.isSelectSubTag14,
    }))
  }
  setIsSelectSubTag20() {
    this.setState((prev) => ({
      isSelectSubTag20: !prev.isSelectSubTag20,
      isSelectSubTag21: false,
      isSelectSubTag22: false,
    }))
  }
  setIsSelectSubTag21() {
    this.setState((prev) => ({
      isSelectSubTag20: false,
      isSelectSubTag21: !prev.isSelectSubTag21,
      isSelectSubTag22: false,
    }))
  }
  setIsSelectSubTag22() {
    this.setState((prev) => ({
      isSelectSubTag20: false,
      isSelectSubTag21: false,
      isSelectSubTag22: !prev.isSelectSubTag22,
    }))
  }
  setX(x) {
    this.setState({ x })
  }
  setY(y) {
    this.setState({ y })
  }
  selectFile(file) {
    this.setState({ file })
  }
  setOpenStatus(openStatus) {
    this.setState((prev) => {
      console.log(prev, openStatus)
      if (prev.openStatus === openStatus) {
        return { ...prev, openStatus: 0 }
      }
      return { ...prev, openStatus }
    })
  }
  setSelectedTag(selectedTag) {
    this.setState({ selectedTag })
  }
  setSelectedSubTag(selectedSubTag) {
    this.setState({ selectedSubTag })
  }
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    const {
      x = 0,
      y = 0,
      file = 0,
      selectedTag = 0,
      selectedSubTag = 0,
      isSelectSubTag00,
      isSelectSubTag01,
      isSelectSubTag02,
      isSelectSubTag03,
      isSelectSubTag10,
      isSelectSubTag11,
      isSelectSubTag12,
      isSelectSubTag13,
      isSelectSubTag14,
      isSelectSubTag20,
      isSelectSubTag21,
      isSelectSubTag22,
    } = this.state
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='瑞兔百宝箱 - 一款提升CG艺术家工作效率的百宝工具箱 - 瑞云渲染'
          description='瑞兔百宝箱（Raytool）是Renderbus瑞云渲染自主开发的一款为提升CG艺术家工作效率的百宝工具箱，包含多款脚本插件、3D插件，供3ds Max、Maya、Cinema 4D用户免费下载使用。'
          keywords='Maya脚本包,3dsmax脚本下载,3D插件下载,脚本插件下载,C4D插件下载'
          sharePostSlug='raytools.html'
        />
        <Instroduction />
        <FetureContent>
          <Wrapper style={{ paddingLeft: '0' }} className='feature-content-wrapper'>
            <ChestTitle>功能以及应用场景一览</ChestTitle>
            <FunctionTitle id='tab1'>
              <TabWrapper className='title1'>
                <FunctionTab
                  className='first-cot '
                  isSelect={selectedTag === 0}
                  onClick={() => {
                    this.setX(0)
                    this.setY(0)
                    this.setSelectedTag(0)
                    this.setSelectedSubTag(0)
                  }}
                >
                  For 3ds Max
                </FunctionTab>
                <FunctionTab
                  className='second-cot '
                  isSelect={selectedTag === 1}
                  onClick={() => {
                    this.setX(1)
                    this.setY(0)
                    this.setSelectedTag(1)
                    this.setSelectedSubTag(0)
                  }}
                >
                  For Maya
                </FunctionTab>
                <FunctionTab
                  className='last-cot '
                  isSelect={selectedTag === 2}
                  onClick={() => {
                    this.setX(2)
                    this.setY(0)
                    this.setSelectedTag(2)
                    this.setSelectedSubTag(0)
                  }}
                >
                  For Cinema 4D
                </FunctionTab>
              </TabWrapper>
            </FunctionTitle>
            <FreeDownload>
              <SoftwareInfo>
                <img src={VIcon} alt='' />
                <SoftwareInfoItem>
                  <SoftwareName>
                    <img src={DownloadIcon} alt='' />
                    <span>{versionList[x].title}</span>
                  </SoftwareName>
                  <Version>
                    <span className='mb-text'>最新版本</span>
                    <span className='version-text'>{versionList[x].newVersion}</span>
                  </Version>
                </SoftwareInfoItem>
                <RightLine />
              </SoftwareInfo>
              <SoftwareInfo>
                <img src={TimeIcon} alt='' />
                <SoftwareInfoItem>
                  <div className='mb-text'>更新时间</div>
                  <div className='version-text'>{versionList[x].updateTime}</div>
                </SoftwareInfoItem>
                <RightLine />
              </SoftwareInfo>
              <SoftwareInfo>
                <img src={VersionIcon} alt='' />
                <SoftwareInfoItem>
                  <div className='mb-text gap-right'>支持软件版本</div>
                  <div className='version-text'>{versionList[x].supportVersion}</div>
                </SoftwareInfoItem>
                <RightLine />
              </SoftwareInfo>
              <SoftwareInfo>
                <Button
                  backgroundColor='#13CB75'
                  font-size='30px'
                  font-family='Source Han Sans CN'
                  font-weight='500'
                  id={versionList[x].id}
                  href={versionList[x].link}
                  download
                  as='a'
                >
                  免费下载
                </Button>
              </SoftwareInfo>
            </FreeDownload>
            <TabMainWrapper class='content'>
              <TabContentWrapper className='main-content '>
                {x === 0 && (
                  <TipWrapper>
                    <TipTitleGroup mbShow={file === 0}>
                      <TipTitle
                        fileSelect={file === 0}
                        isSelect={selectedSubTag === 0}
                        active={isSelectSubTag00}
                        onClick={() => {
                          this.setY(0)
                          this.selectFile(0)
                          this.setSelectedSubTag(0)
                          this.setIsSelectSubTag00()
                        }}
                      >
                        {file === 0 ? (
                          <img src={ScenesSelectIcon} alt='' />
                        ) : (
                          <img src={ScenesUnselectIcon} alt='' />
                        )}
                        <span>场景整理</span>
                      </TipTitle>
                      <TipTitle
                        className='Mobile'
                        fileSelect={file === 1}
                        isSelect={selectedSubTag === 3}
                        active={isSelectSubTag03}
                        onClick={() => {
                          this.setY(3)
                          this.selectFile(1)
                          this.setSelectedSubTag(3)
                          this.setIsSelectSubTag03()
                        }}
                      >
                        {file === 1 ? (
                          <img src={ScenesSelectIcon} alt='' />
                        ) : (
                          <img src={ScenesUnselectIcon} alt='' />
                        )}
                        <span>文件处理</span>
                      </TipTitle>
                    </TipTitleGroup>
                    <VerticalLine></VerticalLine>
                    <FileLine></FileLine>
                    <TipSubWrapper
                      isSelect={selectedSubTag === 0}
                      active={isSelectSubTag00}
                      mbShow={file === 0}
                      onClick={() => {
                        this.setY(0)
                        this.selectFile(0)
                        this.setSelectedSubTag(0)
                        this.setOpenStatus(1)
                        this.setIsSelectSubTag00()
                      }}
                    >
                      材质贴图整理
                      <ArrowIcon active={isSelectSubTag00} />
                    </TipSubWrapper>
                    <CotWrapper className='Mobile' isExpand={isSelectSubTag00}>
                      <CotSubWrapper>
                        {DataMap[0][0].map(
                          (item) =>
                            item.title !== '' && (
                              <CotContent isShowBorder={item.showBorder}>
                                <CotTitle>{item.title}</CotTitle>
                                <CotSubTitle>应用场景</CotSubTitle>
                                <SubContent>
                                  {item.content}
                                  {item.warning ? <p>{item.warning}</p> : null}
                                </SubContent>
                                <Button
                                  backgroundColor='none'
                                  as='a'
                                  href={item.url}
                                  rel='nofollow'
                                  className='descbtn'
                                >
                                  使用说明
                                </Button>
                              </CotContent>
                            ),
                        )}
                      </CotSubWrapper>
                    </CotWrapper>
                    <br />
                    <FileLine></FileLine>
                    <TipSubWrapper
                      isSelect={selectedSubTag === 1}
                      active={isSelectSubTag01}
                      mbShow={file === 0}
                      onClick={() => {
                        this.setY(1)
                        this.selectFile(0)
                        this.setSelectedSubTag(1)
                        this.setIsSelectSubTag01()
                      }}
                    >
                      模型整理
                      <ArrowIcon active={isSelectSubTag01} />
                    </TipSubWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[0][1].length}
                      isExpand={isSelectSubTag01}
                    >
                      <CotSubWrapper>
                        {DataMap[0][1].map((item) => (
                          <CotContent isShowBorder={item.showBorder}>
                            <CotTitle>{item.title}</CotTitle>
                            <CotSubTitle>应用场景</CotSubTitle>
                            <SubContent>
                              {item.content}
                              {item.warning ? <p>{item.warning}</p> : null}
                            </SubContent>
                            <ButtonSubWrapper>
                              <Button backgroundColor='none' as='a' href={item.url} rel='nofollow'>
                                使用说明
                              </Button>
                            </ButtonSubWrapper>
                          </CotContent>
                        ))}
                      </CotSubWrapper>
                    </CotWrapper>
                    <br />
                    <FileLine></FileLine>
                    <TipSubWrapper
                      isSelect={selectedSubTag === 2}
                      active={isSelectSubTag02}
                      mbShow={file === 0}
                      onClick={() => {
                        this.setY(2)
                        this.selectFile(0)
                        this.setSelectedSubTag(2)
                        this.setIsSelectSubTag02()
                      }}
                    >
                      灯光相机整理
                      <ArrowIcon active={isSelectSubTag02} />
                    </TipSubWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[0][2].length}
                      isExpand={isSelectSubTag02}
                    >
                      <CotSubWrapper>
                        {DataMap[0][2].map((item) => (
                          <CotContent isShowBorder={item.showBorder}>
                            <CotTitle>{item.title}</CotTitle>
                            <CotSubTitle>应用场景</CotSubTitle>
                            <SubContent>
                              {item.content}
                              {item.warning ? <p>{item.warning}</p> : null}
                            </SubContent>
                            <ButtonSubWrapper>
                              <Button backgroundColor='none' as='a' href={item.url} rel='nofollow'>
                                使用说明
                              </Button>
                            </ButtonSubWrapper>
                          </CotContent>
                        ))}
                      </CotSubWrapper>
                    </CotWrapper>
                    <TipTitle
                      className='file-handle'
                      fileSelect={file === 1}
                      isSelect={selectedSubTag === 3}
                      active={isSelectSubTag03}
                      onClick={() => {
                        this.setY(3)
                        this.selectFile(1)
                        this.setSelectedSubTag(3)
                        this.setIsSelectSubTag03()
                      }}
                    >
                      {file === 1 ? (
                        <img src={ScenesSelectIcon} alt='' />
                      ) : (
                        <img src={ScenesUnselectIcon} alt='' />
                      )}
                      <span>文件处理</span>
                    </TipTitle>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[0][3].length}
                      isExpand={isSelectSubTag03}
                    >
                      <CotSubWrapper>
                        {DataMap[0][3].map((item) => (
                          <CotContent isShowBorder={item.showBorder}>
                            <CotTitle>{item.title}</CotTitle>
                            <CotSubTitle>应用场景</CotSubTitle>
                            <SubContent>
                              {item.content}
                              {item.warning ? <p>{item.warning}</p> : null}
                            </SubContent>
                            <ButtonSubWrapper>
                              <Button backgroundColor='none' as='a' href={item.url} rel='nofollow'>
                                使用说明
                              </Button>
                            </ButtonSubWrapper>
                          </CotContent>
                        ))}
                      </CotSubWrapper>
                    </CotWrapper>
                  </TipWrapper>
                )}
                {x === 1 && (
                  <TipWrapper className='maya'>
                    <TipMayaWrapper
                      isSelect={selectedSubTag === 0}
                      active={isSelectSubTag10}
                      onClick={() => {
                        this.setY(0)
                        this.setSelectedSubTag(0)
                        this.setIsSelectSubTag10()
                      }}
                    >
                      <div>Raytool</div>
                      <div className='title'>瑞兔工具集</div>
                      <ArrowIcon active={isSelectSubTag10} />
                    </TipMayaWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[1][0].length}
                      isExpand={isSelectSubTag10}
                    >
                      <CotSubWrapper>
                        {DataMap[1][0].map((item) => (
                          <CotContent isShowBorder={item.showBorder}>
                            <CotTitle>{item.title}</CotTitle>
                            <CotSubTitle>应用场景</CotSubTitle>
                            <SubContent>
                              {item.content}
                              {item.warning ? <p>{item.warning}</p> : null}
                            </SubContent>
                            <ButtonSubWrapper>
                              {item.title !== '' && item.url !== '' && (
                                <Button
                                  backgroundColor='none'
                                  as='a'
                                  href={item.url}
                                  rel='nofollow'
                                >
                                  使用说明
                                </Button>
                              )}
                            </ButtonSubWrapper>
                          </CotContent>
                        ))}
                      </CotSubWrapper>
                    </CotWrapper>
                    <TipMayaWrapper
                      isSelect={selectedSubTag === 1}
                      active={isSelectSubTag11}
                      onClick={() => {
                        this.setY(1)
                        this.setSelectedSubTag(1)
                        this.setIsSelectSubTag11()
                      }}
                    >
                      <div>Model Tools</div>
                      <div className='title'>建模工具集</div>
                      <ArrowIcon active={isSelectSubTag11} />
                    </TipMayaWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[1][1].length}
                      isExpand={isSelectSubTag11}
                    >
                      <CotSubWrapper>
                        {DataMap[1][1].map((item) => (
                          <CotContent isShowBorder={item.showBorder}>
                            <CotTitle>{item.title}</CotTitle>
                            <CotSubTitle>应用场景</CotSubTitle>
                            <SubContent>
                              {item.content}
                              {item.warning ? <p>{item.warning}</p> : null}
                            </SubContent>
                            <ButtonSubWrapper>
                              {item.title !== '' && item.url !== '' && (
                                <Button
                                  backgroundColor='none'
                                  as='a'
                                  href={item.url}
                                  rel='nofollow'
                                >
                                  使用说明
                                </Button>
                              )}
                            </ButtonSubWrapper>
                          </CotContent>
                        ))}
                      </CotSubWrapper>
                    </CotWrapper>
                    <TipMayaWrapper
                      isSelect={selectedSubTag === 2}
                      active={isSelectSubTag12}
                      onClick={() => {
                        this.setY(2)
                        this.setSelectedSubTag(2)
                        this.setIsSelectSubTag12()
                      }}
                    >
                      <div>Riggle Tools</div>
                      <div className='title'>绑定工具集</div>
                      <ArrowIcon active={isSelectSubTag12} />
                    </TipMayaWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[1][2].length}
                      isExpand={isSelectSubTag12}
                    >
                      <CotSubWrapper>
                        {DataMap[1][2].map(
                          (item) =>
                            item.title !== '' && (
                              <CotContent isShowBorder={item.showBorder}>
                                <CotTitle>{item.title}</CotTitle>
                                <CotSubTitle>应用场景</CotSubTitle>
                                <SubContent>
                                  {item.content}
                                  {item.warning ? <p>{item.warning}</p> : null}
                                </SubContent>
                                <ButtonSubWrapper>
                                  {item.title !== '' && item.url !== '' && (
                                    <Button
                                      backgroundColor='none'
                                      as='a'
                                      href={item.url}
                                      rel='nofollow'
                                    >
                                      使用说明
                                    </Button>
                                  )}
                                </ButtonSubWrapper>
                              </CotContent>
                            ),
                        )}
                      </CotSubWrapper>
                    </CotWrapper>
                    <TipMayaWrapper
                      isSelect={selectedSubTag === 3}
                      active={isSelectSubTag13}
                      onClick={() => {
                        this.setY(3)
                        this.setSelectedSubTag(3)
                        this.setIsSelectSubTag13()
                      }}
                    >
                      <div>Animation Tools</div>
                      <div className='title'>动画工具集</div>
                      <ArrowIcon active={isSelectSubTag13} />
                    </TipMayaWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[1][3].length}
                      isExpand={isSelectSubTag13}
                    >
                      <CotSubWrapper>
                        {DataMap[1][3].map(
                          (item) =>
                            item.title !== '' && (
                              <CotContent isShowBorder={item.showBorder}>
                                <CotTitle>{item.title}</CotTitle>
                                <CotSubTitle>应用场景</CotSubTitle>
                                <SubContent>
                                  {item.content}
                                  {item.warning ? <p>{item.warning}</p> : null}
                                </SubContent>
                                <ButtonSubWrapper>
                                  {item.title !== '' && item.url !== '' && (
                                    <Button
                                      backgroundColor='none'
                                      as='a'
                                      href={item.url}
                                      rel='nofollow'
                                    >
                                      使用说明
                                    </Button>
                                  )}
                                </ButtonSubWrapper>
                              </CotContent>
                            ),
                        )}
                      </CotSubWrapper>
                    </CotWrapper>
                    <TipMayaWrapper
                      isSelect={selectedSubTag === 4}
                      active={isSelectSubTag14}
                      onClick={() => {
                        this.setY(4)
                        this.setSelectedSubTag(4)
                        this.setIsSelectSubTag14()
                      }}
                    >
                      <div>Rendering Tools</div>
                      <div className='title'>渲染灯光工具集</div>
                      <ArrowIcon active={isSelectSubTag14} />
                    </TipMayaWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[1][4].length}
                      isExpand={isSelectSubTag14}
                    >
                      <CotSubWrapper>
                        {DataMap[1][4].map(
                          (item) =>
                            item.title !== '' && (
                              <CotContent isShowBorder={item.showBorder}>
                                <CotTitle>{item.title}</CotTitle>
                                <CotSubTitle>应用场景</CotSubTitle>
                                <SubContent>
                                  {item.content}
                                  {item.warning ? <p>{item.warning}</p> : null}
                                </SubContent>
                                <ButtonSubWrapper>
                                  {item.title !== '' && item.url !== '' && (
                                    <Button
                                      backgroundColor='none'
                                      as='a'
                                      href={item.url}
                                      rel='nofollow'
                                    >
                                      使用说明
                                    </Button>
                                  )}
                                </ButtonSubWrapper>
                              </CotContent>
                            ),
                        )}
                      </CotSubWrapper>
                    </CotWrapper>
                  </TipWrapper>
                )}
                {x === 2 && (
                  <TipWrapper className='c4d'>
                    <TipMayaWrapper
                      isSelect={selectedSubTag === 0}
                      active={isSelectSubTag20}
                      onClick={() => {
                        this.setY(0)
                        this.setSelectedSubTag(0)
                        this.setIsSelectSubTag20()
                      }}
                    >
                      <div>Buildings</div>
                      <div className='title'>建模</div>
                      <ArrowIcon active={isSelectSubTag20} />
                    </TipMayaWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[2][0].length}
                      isExpand={isSelectSubTag20}
                    >
                      <CotSubWrapper>
                        {DataMap[2][0].map((item) => (
                          <CotContent isShowBorder={item.showBorder}>
                            <CotTitle>{item.title}</CotTitle>
                            <CotSubTitle>应用场景</CotSubTitle>
                            <SubContent>
                              {item.content}
                              {item.warning ? <p>{item.warning}</p> : null}
                            </SubContent>
                            <ButtonSubWrapper>
                              {item.title !== '' && item.url !== '' && (
                                <Button
                                  backgroundColor='none'
                                  as='a'
                                  href={item.url}
                                  rel='nofollow'
                                >
                                  使用说明
                                </Button>
                              )}
                            </ButtonSubWrapper>
                          </CotContent>
                        ))}
                      </CotSubWrapper>
                    </CotWrapper>
                    <TipMayaWrapper
                      isSelect={selectedSubTag === 1}
                      active={isSelectSubTag21}
                      onClick={() => {
                        this.setY(1)
                        this.setSelectedSubTag(1)
                        this.setIsSelectSubTag21()
                      }}
                    >
                      <div>Commands</div>
                      <div className='title'>命令</div>
                      <ArrowIcon active={isSelectSubTag21} />
                    </TipMayaWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[2][1].length}
                      isExpand={isSelectSubTag21}
                    >
                      <CotSubWrapper>
                        {DataMap[2][1].map((item) => (
                          <CotContent isShowBorder={item.showBorder}>
                            <CotTitle>{item.title}</CotTitle>
                            <CotSubTitle>应用场景</CotSubTitle>
                            <SubContent>
                              {item.content}
                              {item.warning ? <p>{item.warning}</p> : null}
                            </SubContent>
                            <ButtonSubWrapper>
                              {item.title !== '' && item.url !== '' && (
                                <Button
                                  backgroundColor='none'
                                  as='a'
                                  href={item.url}
                                  rel='nofollow'
                                >
                                  使用说明
                                </Button>
                              )}
                            </ButtonSubWrapper>
                          </CotContent>
                        ))}
                      </CotSubWrapper>
                    </CotWrapper>
                    <TipMayaWrapper
                      isSelect={selectedSubTag === 2}
                      active={isSelectSubTag22}
                      onClick={() => {
                        this.setY(2)
                        this.setSelectedSubTag(2)
                        this.setIsSelectSubTag22()
                      }}
                    >
                      <div>GUI Tools&nbsp;</div>
                      <div className='title'>GUI工具</div>
                      <ArrowIcon active={isSelectSubTag22} />
                    </TipMayaWrapper>
                    <CotWrapper
                      className='Mobile'
                      count={DataMap[2][2].length}
                      isExpand={isSelectSubTag22}
                    >
                      <CotSubWrapper>
                        {DataMap[2][2].map(
                          (item) =>
                            item.title !== '' && (
                              <CotContent isShowBorder={item.showBorder}>
                                <CotTitle>{item.title}</CotTitle>
                                <CotSubTitle>应用场景</CotSubTitle>
                                <SubContent>
                                  {item.content}
                                  {item.warning ? <p>{item.warning}</p> : null}
                                </SubContent>
                                <ButtonSubWrapper>
                                  {item.title !== '' && item.url !== '' && (
                                    <Button
                                      backgroundColor='none'
                                      as='a'
                                      href={item.url}
                                      rel='nofollow'
                                    >
                                      使用说明
                                    </Button>
                                  )}
                                </ButtonSubWrapper>
                              </CotContent>
                            ),
                        )}
                      </CotSubWrapper>
                    </CotWrapper>
                  </TipWrapper>
                )}
                <CotWrapper className='Web'>
                  <CotSubWrapper>
                    {DataMap[x][y].map((item) => (
                      <CotContent isShowBorder={item.showBorder}>
                        <CotTitle>{item.title}</CotTitle>
                        {item.title !== '' && <CotSubTitle>应用场景</CotSubTitle>}
                        <SubContent>
                          {item.content}
                          {item.warning ? <p>{item.warning}</p> : null}
                        </SubContent>
                        {item.title !== '' && item.url !== '' && (
                          <ButtonSubWrapper>
                            <Button backgroundColor='none' as='a' href={item.url} rel='nofollow'>
                              使用说明
                            </Button>
                          </ButtonSubWrapper>
                        )}
                      </CotContent>
                    ))}
                  </CotSubWrapper>
                </CotWrapper>
              </TabContentWrapper>
            </TabMainWrapper>
          </Wrapper>
        </FetureContent>
        <TreasureContent>
          <Wrapper>
            <ChestTitle>工具安装使用</ChestTitle>
            {x === 0 && (
              <InstallContent>
                <InstallWrapper>
                  <InstallText>
                    <InstallTitle>安装和运行</InstallTitle>
                    <InstallSubText>
                      <p>安装前，建议使用管理员权限运行3ds Max程序，并在空场景情况下操作;</p>
                      <p>
                        （本安装教程适用于3ds Max 2012 - 3ds Max 2021，点击
                        <a href='https://www.renderbus.com/support-new/support-id-1016/' alt=''>
                          3ds Max 2022
                        </a>
                        可查看其他版本的安装使用说明）
                      </p>
                      <p>安装方式：</p>
                      <p>方法一： 将“瑞兔百宝箱.mzp”安装包文件拖入3ds Max视窗中;</p>
                      <p>
                        方法二：进入3ds Max菜单栏的Scripting&gt;Run
                        Script...，选择下载的“瑞兔百宝箱.mzp”文件来运行，提示安装完成后，即可在菜单栏找到“瑞兔百宝箱”的菜单目录;
                      </p>
                      <br />
                      <p>注意：</p>
                      <p>1. 当分辨率较低时3ds Max菜单较多会有折叠，展开即可看到；</p>
                      <p>2. 如遇有显示乱码，可以在自定义预设置中将语言设置为中文即可；</p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={Install} alt='安装和运行' />
                  </InstallImg>
                </InstallWrapper>
                <InstallWrapper className='mobile-hide'>
                  <InstallImg>
                    <img src={Explain} alt='工具包使用说明' />
                  </InstallImg>
                  <InstallText className='centerContent'>
                    <InstallTitle>工具包使用说明</InstallTitle>
                    <InstallSubText>
                      <p>
                        1.
                        打开菜单栏中的“瑞兔百宝箱”，点击相应标签可以切换不同分类，拖动右边滚动条可查看使用更多功能；
                      </p>
                      <p>2. 点击相应按钮即可打开对应功能的脚本;</p>
                      <p>3. “自定义栏”中可以点击“+”号添加本地自定义脚本(“.ms”，“.mse”等格式);</p>
                      <p>
                        4.
                        在“自定义”栏目中，添加的脚本按钮上可以右键删除或者只管理自定义目录脚本文件；
                      </p>
                    </InstallSubText>
                  </InstallText>
                </InstallWrapper>
                <InstallWrapper className='mobile'>
                  <InstallText>
                    <InstallTitle>工具包使用说明</InstallTitle>
                    <InstallSubText>
                      <p>
                        1.
                        打开菜单栏中的“瑞兔百宝箱”，点击相应标签可以切换不同分类，拖动右边滚动条可查看使用
                        更多功能；
                      </p>
                      <p>2. 点击相应按钮即可打开对应功能的脚本;</p>
                      <p>3. “自定义栏”中可以点击“+”号添加本地自定义脚本(“.ms”,“.mse”等格式);</p>
                      <p>
                        4.
                        在“自定义”栏目中，添加的脚本按钮上可以右键删除或者只管理自定义目录脚本文件；
                      </p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={Explain} alt='工具包使用说明' />
                  </InstallImg>
                </InstallWrapper>
                <InstallWrapper>
                  <InstallText className='centerContentl'>
                    <InstallTitle>如何卸载</InstallTitle>
                    <InstallSubText>
                      <p>
                        卸载方式很简单，点击“瑞兔百宝箱”，选择子菜单中的“卸载”功能，在弹出确认框中选择“是”即可完成卸载。
                      </p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={Unstall} alt='如何卸载' />
                  </InstallImg>
                </InstallWrapper>
              </InstallContent>
            )}
            {x === 1 && (
              <InstallContent>
                <InstallWrapper>
                  <InstallText>
                    <InstallTitle>安装和运行</InstallTitle>
                    <InstallSubText>
                      <p>1.下载Raytool for Maya工具包;</p>
                      <p>
                        2.解压安装包并访问安装包文件，可以看到“拖拽安装.mel”文件，将该文件拖拽到Maya软件界面内;
                      </p>
                      <p>
                        3.查看Maya软件界面左侧的工具栏，如有看到Raytool的图标，则表示安装成功，点击图标即可展开工具面板;
                      </p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={InstallMaya} alt='Maya安装和运行' />
                  </InstallImg>
                </InstallWrapper>
                <InstallWrapper className='mobile-hide'>
                  <InstallImg>
                    <img src={ExplainMaya} alt='Maya工具包使用说明' />
                  </InstallImg>
                  <InstallText>
                    <InstallTitle>工具面板简介</InstallTitle>
                    <InstallSubText>
                      <p>
                        工具面板主要分为三个区域：左边的工具分区菜单栏、右边的工具列表面板以及设置面板。点击工具分区菜单栏上模块，右边会展示工具包预先准备的小工具供用户使用，下面是几个预置模块的说明：
                      </p>
                      <p>
                        （1）Raytool <br />
                        *瑞兔工具集，解决渲染中遇到的问题，内含多个本地渲染检查相关小工具；
                      </p>
                      <p>
                        （2）Model Tools <br />
                        *建模工具集，内含与建模制作相关的小工具
                      </p>
                      <p>
                        （3）Riggle Tools <br />
                        *绑定工具集，内含与绑定制作相关的小工具
                      </p>
                      <p>
                        （4）Animation Tools <br />
                        *动画工具集，内含与动画制作相关的小工具
                      </p>
                      <p>
                        （5）Rendering Tools <br />
                        *渲染灯光工具集，内含与灯光渲染制作相关的小工具
                      </p>
                    </InstallSubText>
                  </InstallText>
                </InstallWrapper>
                <InstallWrapper className='mobile'>
                  <InstallText>
                    <InstallTitle>工具面板简介</InstallTitle>
                    <InstallSubText>
                      <p>
                        工具面板主要分为三个区域：左边的工具分区菜单栏、右边的工具列表面板以及设置面板。点击工具分区菜单栏上模块，右边会展示工具包预先准备的小工具供
                        用户使用,下面是几个预置模块的说明：
                      </p>
                      <p>
                        （1）Raytool <br />
                        *瑞兔工具集，解决渲染中遇到的问题，内含多个本地渲染检查相关小工具；
                      </p>
                      <p>
                        （2）Model Tools <br />
                        *建模工具集，内含与建模制作相关的小工具
                      </p>
                      <p>
                        （3）Riggle Tools <br />
                        *绑定工具集，内含与绑定制作相关的小工具
                      </p>
                      <p>
                        （4）Animation Tools <br />
                        *动画工具集，内含与动画制作相关的小工具
                      </p>
                      <p>
                        （5）Rendering Tools <br />
                        *渲染灯光工具集，内含与灯光渲染制作相关的小工具
                      </p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={ExplainMaya} alt='Maya工具包使用说明' />
                  </InstallImg>
                </InstallWrapper>
                <InstallWrapper className='add-maya'>
                  <InstallText>
                    <InstallTitle>添加自定义插件的方法</InstallTitle>
                    <InstallSubText>
                      <p>
                        工具包面板中除了预先准备的小工具外，用户也可自行添加自定义插件到相应模块中，方便管理与使用：
                      </p>
                      <p>（1）找到工具根目录下的“scripts”文件夹； </p>
                      <p>（2）在“scripts”文件夹内可看到各个模块文件夹； </p>
                      <p>（3）每个模块内含一个名为“scripts”的脚本文件夹； </p>
                      <p>
                        （4）在模块的“scripts”文件夹内新建新的文件夹并自定义名称（注意：文件名只能为英文或下划线，不可出现中文、特殊符号和空格）；{' '}
                      </p>
                      <p>
                        （5）回到Maya软件中Raytool界面，切换或刷新面板就能看到刚新建工具的模块中新增自定义的插件；{' '}
                      </p>
                      <p>
                        （6）回到新创建的文件夹下，在文件夹中建立同名的py文件，写好脚本内容，点击Raytool中该图标就能执行脚本；
                      </p>
                      <p>
                        （7）新增的插件支持自定义封面，只需在同目录下建立一个名为icon的文件夹，往里面放入图片（支持jpg，png，gif等常规图片），默认会按照名字排序自动选择靠前的一张图为封面；
                      </p>
                      <p>（8）切换模块或刷新Raytool面板，可以看到自定义插件更新了封面；</p>
                      <br />
                      <p>
                        附加功能说明：
                        <br />
                        文件夹是支持嵌套的，如果在目录下继续创建script文件夹，重复上面操作就能进入下一级功能的添加。
                      </p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={AddPlugin} alt='添加自定义插件' />
                  </InstallImg>
                </InstallWrapper>
              </InstallContent>
            )}
            {x === 2 && (
              <InstallContent>
                <InstallWrapper>
                  <InstallText>
                    <InstallTitle>安装和运行</InstallTitle>
                    <InstallSubText>
                      <p>1.下载Raytool for Cinema 4D安装压缩包；</p>
                      <p>
                        2.解压安装包并访问安装包文件，可以看到英文版跟中文版，每个语言文件夹里面又含4个不同的安装版本，文件夹写了分别对应的Cinema
                        4D版本：
                      </p>
                      <p>○ Raytool for Cinema 4D_R21-S22，对应的Cinema 4D版本是R21和S22；</p>
                      <p>○ Raytool for Cinema 4D_R23，对应的Cinema 4D版本是R23；</p>
                      <p>
                        ○ Raytool for Cinema 4D_S24-2023.1，对应的Cinema
                        4D版本是S24到2023.1之内的版本;
                      </p>
                      <p>○ Raytool for Cinema 4D_2023.2，对应的Cinema 4D版本是2023.2的版本;</p>
                      <p>
                        3.选择对应版本文件夹，进入后将“C4dRaytool”文件夹整体拷贝至Cinema
                        4D的预置文件夹的“plugins”目录下，关闭、重启Cinema 4D软件；
                      </p>
                      <p>
                        4.查看Cinema
                        4D菜单栏，如有看到Raytool的图标，则表示安装成功，点击图标即可展开工具面板;
                      </p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={InstallC4D} alt='Cinema 4D安装和运行' />
                  </InstallImg>
                </InstallWrapper>
                <InstallWrapper className='mobile-hide'>
                  <InstallImg>
                    <img src={ExplainC4D} alt='Cinema 4D工具面板简介' />
                  </InstallImg>
                  <InstallText>
                    <InstallTitle>工具面板简介</InstallTitle>
                    <InstallSubText>
                      <p>面板中工具模块主要分三大功能模块，分别是建模、命令、GUI工具：</p>
                      <p>
                        （1）建模（Building） <br />
                        *同Commands，为直接执行并起效，不会有界面弹窗，展开其二级菜单，选择相应功能模块即可一键执行，无下一步操作；
                      </p>
                      <p>
                        （2）命令（Commands） <br />
                        *为Commands直接执行并起效，不会有界面弹窗，展开其二级菜单，选择相应功能模块即可一键执行，无下一步操作；
                      </p>
                      <p>
                        （3）GUI工具 <br />
                        *包含FPS检查、MD5检查、对象点面信息、打开文件夹以及材质转换，点击相应功能模块，在屏幕中央会开启一个独立设置窗口，设置窗口面板上相关参数后执行下一步操作；
                      </p>
                    </InstallSubText>
                  </InstallText>
                </InstallWrapper>
                <InstallWrapper className='mobile'>
                  <InstallText>
                    <InstallTitle>工具面板简介</InstallTitle>
                    <InstallSubText>
                      <p>面板中工具模块主要分三大功能模块，分别是建模、命令、GUI工具：</p>
                      <p>
                        （1）建模（Building） <br />
                        *同Commands，为直接执行并起效，不会有界面弹窗，展开其二级菜单，选择相应功能模块即可一键执行，无下一步操作；
                      </p>
                      <p>
                        （2）命令（Commands） <br />
                        *为Commands直接执行并起效，不会有界面弹窗，展开其二级菜单，选择相应功能模块即可一键执行，无下一步操作；
                      </p>
                      <p>
                        （3）GUI工具 <br />
                        *包含FPS检查、MD5检查、对象点面信息、打开文件夹以及材质转换，点击相应功能模块，在屏幕中央会开启一个独立设置窗口，设置窗口面板上相关参数后执行下一步操作；
                      </p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={ExplainC4D} alt='Cinema 4D工具面板简介' />
                  </InstallImg>
                </InstallWrapper>
                <InstallWrapper>
                  <InstallText className='centerContentl'>
                    <InstallTitle>如何卸载</InstallTitle>
                    <InstallSubText>
                      <p>
                        卸载方式很简单。关闭Cinema
                        4D软件，将“plugins”文件夹下的“C4dRaytool”删掉即可卸载此工具包。
                      </p>
                      <p>
                        *特别提醒： <br />
                        单个功能模块无法进行单独安装与卸载（即若想只单独安装“RT_材质转换”工具是不可安装的，它是含在整个工具包内）
                      </p>
                    </InstallSubText>
                  </InstallText>
                  <InstallImg>
                    <img src={UninstallC4D} alt='Cinema 4D卸载' />
                  </InstallImg>
                </InstallWrapper>
              </InstallContent>
            )}
          </Wrapper>
        </TreasureContent>
        <TreasureChestBanner />
      </Layout>
    )
  }
}

export default TreasureChest
