import styled from 'styled-components'
import {
  Section,
  H1,
  SectionTitle,
  PriceSubSection,
  TreasureSection,
  Wrapper,
} from '@renderbus/common/components'

import {
  Media,
  hideSMDown,
  typography,
  spacing,
  color,
  hideMDDown,
  hideSMUp,
} from '@renderbus/common/theme'

export const InstallContent = styled.div`
  width: 1399px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 100%;
  }
`
export const InstallWrapper = styled.div`
  height: 470px;
  width: 100%;
  display: flex;
  margin-top: 80px;
  &.mobile-hide {
    ${Media.lessThan(Media.small)} {
      display: none !important;
    }
  }
  &.mobile {
    display: none;
    ${Media.lessThan(Media.small)} {
      display: flex;
    }
  }
  &.add-maya {
    height: 700px;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 40px 0 0;
    &.add-maya {
      height: auto;
    }
  }
`
export const InstallText = styled.div`
  padding: 0 20px 0 50px;
  &.centerContent {
    margin-top: 89px;
  }
  &.centerContentl {
    margin-top: 114px;
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 20px 0 10px;
    margin-top: 0 !important;
    width: 100%;
    margin-bottom: 40px;
  }
`
export const InstallTitle = styled.div`
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
  ${Media.lessThan(Media.small)} {
    font-size: 16px;
    line-height: 19px;
    width: 100%;
  }
`
export const InstallSubText = styled.div`
  width: 643px;
  height: 241px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 28px;
  a {
    color: #13cb75;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 14px;
    line-height: 16px;
    height: 100%;
    width: 100%;
    p {
      margin: 5px 0px;
    }
  }
`
export const TabMainWrapper = styled.div``

export const InstallImg = styled.div`
  > img {
    width: 660px;
    height: 400px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    text-align: center;
    height: 100%;
    > img {
      width: 350px;
      height: 247px;
      border-radius: 5px;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${Media.lessThan(Media.small)} {
    justify-content: center;
  }
`
export const BannerContainer = styled.div`
  position: relative;
  padding-top: 60px;
  height: 600px;
  color: white;
  p {
    font-size: ${typography.h3};
  }
  ${Media.lessThan(Media.small)} {
    height: 300px;
    h1 {
      font-size: ${typography.h2};
    }
    p {
      font-size: ${typography.text};
    }
  }
`

export const DesktopOnlySection = styled(PriceSubSection)`
  ${hideSMDown};
`
export const YouthCloudContainer = styled.div`
  position: relative;
  height: 600px;
  color: white;
  text-align: center;
  padding: 100px 0;
  p {
    line-height: 2em;
  }
  button + button {
    margin-left: ${spacing.base};
  }
  ${Media.lessThan(Media.small)} {
    height: 300px;
    padding: 30px 0;
    p {
      font-size: ${typography.textSmall};
    }
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`
export const FunctionTitle = styled.div`
  width: 1400px;
  height: 70px;
  display: flex;
  margin-top: 60px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 37px;
    margin-top: 40px;
  }
`
export const FunctionTab = styled.div`
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: ${prop => (prop.isSelect ? '#13CB75' : '#999999')};
  background: ${prop => (prop.isSelect ? '#333333' : '#2D2D2D')};
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #13cb75;
    cursor: pointer;
  }
  &.first-cot {
    width: 260px;
    position: relative;
    border-radius: 10px 0px 0px 0px;
    ${Media.lessThan(Media.small)} {
      width: 118px;
      border-radius: 5px 0px 0px 0px;
    }
  }
  &.second-cot {
    width: 260px;
    position: relative;
    border-radius: 0px 0px 0px 0px;
    ${Media.lessThan(Media.small)} {
      width: 97px;
    }
  }
  &.last-cot {
    width: 260px;
    position: relative;
    border-radius: 0px 10px 0px 0px;
    ${Media.lessThan(Media.small)} {
      width: 118px;
      border-radius: 0px 5px 0px 0px;
    }
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textSmall};
    color: ${prop => (prop.isSelect ? '#13CB75' : '#808080')};
    background: ${prop => (prop.isSelect ? '#242424' : '#1F1F1F')};
  }
`
export const FreeDownload = styled.div`
  display: flex;
  position: relative;
  > div:last-child {
    border-radius: 0px 10px 0px 0px;
  }
  :after {
    content: '';
    display: block;
    width: 96%;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #444444;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    padding-top: 18px;
    background: #242424;
    :after {
      display: none;
    }
  }
`
export const SoftwareInfo = styled.div`
  width: 350px;
  height: 239px;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > img {
    width: 52px;
    margin-right: 20px;
  }
  a {
    width: 194px;
    height: 53px;
    border-radius: 26px;
    line-height: 53px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 100%;
    justify-content: left;
    padding: 0 18px;
    background: #242424;
    > img {
      display: none;
    }
    a {
      width: 100% !important;
      height: 40px;
      line-height: 40px;
      margin-top: 18px;
    }
  }
`
export const SoftwareInfoItem = styled.div`
  font-size: ${typography.h4};
  color: #ffffff;
  height: 50px;
  .version-text {
    color: #999999;
    font-size: ${typography.textSmall};
  }
  .mb-text {
    margin-right: 10px;
  }
  ${Media.lessThan(Media.small)} {
    height: 100%;
    .mb-text {
      display: inline-block;
      color: #99999a;
      font-size: ${typography.textThin};
      margin-right: 63px;
    }
    .version-text {
      display: inline-block;
      color: #e8e8e8;
    }
    .gap-right {
      margin-right: 39px;
    }
  }
`
export const SoftwareName = styled.div`
  img {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 14px;
    img {
      display: inline-block;
      width: 15px;
      margin-right: 8px;
      margin-top: -5px;
    }
  }
`
export const Version = styled.div`
  color: #999999;
  font-size: ${typography.textSmall};
  ${Media.lessThan(Media.small)} {
    margin-top: 10px;
  }
`
export const RightLine = styled.div`
  width: 1px;
  height: 51px;
  background: #444444;
  position: absolute;
  right: 0;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    justify-content: left;
  }
`
export const TabContentWrapper = styled.div`
  width: 1400px;
  display: none;
  background: #333333;
  border-radius: 0 0 10px 10px;
  &.main-content {
    display: flex;
    ${Media.lessThan(Media.small)} {
      display: block;
      width: 100%;
    }
  }
`
export const ArrowIcon = styled.i`
  display: block;
  ${hideSMUp};
  font-style: normal;
  position: absolute;
  color: white;
  width: 28px;
  height: 28px;
  ${p => p.active && 'transform: rotate(180deg)'};
  :before,
  :after {
    content: '';
    pointer-events: none;
    height: 2px;
    width: 14px;
    top: 0.75em;
    position: absolute;
    background-color: currentColor;
    border-radius: 1px;
  }
  :before {
    transform: rotate(45deg);
  }
  :after {
    transform: rotate(-45deg);
    left: 9px;
  }
  ${Media.lessThan(Media.small)} {
    width: 20px;
    height: 12px;
  }
`
export const WrapperSub = styled.div`
  width: 100%;
`
export const CotWrapper = styled.div`
  width: 1050px;
  background: #333333;
  border-radius: 10px;
  &.Web {
    ${hideSMDown}
  }
  &.Mobile {
    ${hideSMUp}
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    display: ${props => !props.isExpand && 'none'};
    background: #242424;
    margin-top: -30px;
  }
`
export const TipSubWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: ${prop => (prop.isSelect ? '#13CB75' : '#ffffff')};
  line-height: 24px;
  width: 194px;
  background: ${prop => (prop.isSelect ? '#4B4B4B' : '')};
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 27.5px;
  cursor: pointer;
  &:hover {
    color: #13cb75;
  }
  ${Media.lessThan(Media.small)} {
    margin-left: 0 !important;
    height: 46px !important;
    font-size: 16px;
    width: 100%;
    position: relative;
    display: ${prop => (prop.mbShow ? 'inline-block' : 'none')};
    ${ArrowIcon} {
      left: calc(100% - 40px);
      top: ${prop => (prop.active ? '24px' : '10px')};
    }
    margin-bottom: 18px;
  }
`
export const TipWrapper = styled.div`
  width: 350px;
  text-align: left;
  border-right: 1px solid #444444;
  padding: 30px 0 0 83px;
  &.file-content {
    ${Media.lessThan(Media.small)} {
    }
  }
  &.maya,
  &.c4d {
    padding: 15px 0 0 67px;
  }
  ${Media.lessThan(Media.small)} {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
    position: relative;
    margin-right: 0px;
    padding: 42px 0 40px 0;
    background: #242424;
    &.maya,
    &.c4d {
      padding: 42px 0 40px 0;
      ${CotWrapper} {
        margin-top: -18px;
      }
    }
  }
  ${TipSubWrapper} {
    padding-left: 8px;
    height: 42px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 42px;
    ${Media.lessThan(Media.small)} {
      padding-left: 20px;
      background: #333333;
      width: 100%;
      border-radius: 0;
    }
  }
`
export const FileLine = styled.div`
  width: 18px;
  height: 1px;
  background: #8e8e8e;
  display: inline-block;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const VerticalLine = styled.div`
  width: 1px;
  height: 166px;
  background: #8e8f8e;
  position: absolute;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const TipTitle = styled.div`
  color: ${prop => (prop.fileSelect ? '#13cb75' : '#ffffff')};
  font-size: ${typography.h4};
  display: flex;
  margin-left: -10px;
  margin-bottom: 20px;
  cursor: pointer;
  &.file-handle {
    margin-bottom: 28px;
  }
  img {
    margin-right: 18px;
  }
  &.Mobile {
    display: none;
  }
  &:hover {
    color: #13cb75;
  }
  ${Media.lessThan(Media.small)} {
    margin-left: 0;
    margin-bottom: 3px;
    position: relative;
    &:after {
      content: '';
      display: ${prop => (prop.fileSelect ? 'block' : 'none')};
      width: 64px;
      height: 1px;
      background: #13cb75;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      margin: auto;
    }
    img {
      display: none;
    }
    &.Mobile {
      display: block;
    }
    &.file-handle {
      display: none;
    }
  }
`
export const TipMayaWrapper = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  width: 218px;
  height: 54px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  color: ${prop => (prop.isSelect ? '#13CB75' : '#ffffff')};
  background: ${prop => (prop.isSelect ? '#4B4B4B' : '')};
  border-radius: 5px;
  padding-left: 18px;
  cursor: pointer;
  .title {
    font-size: 14px;
  }
  &:hover {
    color: #13cb75;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 54px;
    border-radius: 0;
    background: #333333;
    margin-bottom: 18px;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    .title {
      font-size: 18px;
    }
    ${ArrowIcon} {
      left: calc(100% - 40px);
      height: 25px;
    }
  }
`
export const TipTitleGroup = styled.div`
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    border-bottom: 1px solid #444444;
    display: flex;
    gap: 27px;
    padding-left: 17px;
    margin-bottom: ${prop => (prop.mbShow ? '30px' : '0')};
  }
`
export const CotSubWrapper = styled.div`
  width: 1050px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`
export const CotContent = styled.div`
  width: 33.3%;
  height: 340px;
  border-bottom: ${p => (p.isShowBorder[1] ? '1px solid #444444' : '0')};
  border-right: ${p => (p.isShowBorder[0] ? '1px solid #444444' : '0')};
  padding: 30px 10px 10px 14px;
  position: relative;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #444444 !important;
    border: none;
    padding: 24px 20px;
    .descbtn {
      border: 1px solid ${color.primary};
      color: ${color.primary};
      height: 30px;
      line-height: 28px;
      width: 120px;
      margin-top: 18px;
      :hover {
        background-color: ${color.primary};
        color: #fff;
      }
    }
  }
`
export const CotTitle = styled.div`
  width: 200px;
  height: 20px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #13cb75;
  line-height: 24px;
`
export const CotSubTitle = styled.div`
  width: 100%;
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  padding: 10px 0 31px 0;
`
export const SubContent = styled.div`
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: 100%;
    p {
      margin: 14px 0 0;
    }
  }
`
export const ButtonSubWrapper = styled.div`
  ${Media.lessThan(Media.small)} {
    width: 160px;
  }
  > a {
    position: absolute;
    bottom: 20px;
    height: 32px;
    line-height: 29px;
    border: 1px solid #13cb75;
    color: #13cb75;
    bottom: 30px;
    ${Media.lessThan(Media.small)} {
      position: static;
      margin-top: 18px;
      line-height: 26px;
    }
    :hover {
      background-color: #13cb75 !important;
      color: #ffffff !important;
    }
  }
`
export const SubButton = styled.div`
  color: red;
`

export const LineLight = styled.div`
  width: 120px;
  height: 2px;
  background: #13cb75;
`

export const Tips = styled.p`
  text-align: center;
  margin-top: 40px;
  color: ${color.prompt};
  font-size: ${typography.textSmall};
`
export const FetureContent = styled(Section)`
  padding: 86px 0 94px 0;
  z-index: 100;
  background-color: #181818;
  ${Wrapper} {
    margin-top: -660px;
  }
  ${Media.lessThan(Media.small)} {
    padding: 0;
    background-color: transparent;
    ${Wrapper} {
      padding: 0px;
      margin-top: -100px;
    }
  }
`
export const PriceTitle = styled(SectionTitle)`
  font-size: ${typography.title};
`
export const ChestTitle = styled(SectionTitle)`
  font-size: ${typography.title};
  margin-bottom: 6px;
  ${Media.lessThan(Media.small)} {
    font-size: 20px !important;
    text-align: left;
    margin-left: 18px;
  }
`
export const PriceSubTitle = styled.h5`
  margin: 0 auto;
  font-size: ${typography.textSmall};
  text-align: center;
  color: #999;
  ${hideMDDown};
`
export const PriceTips = styled.div`
  margin: ${spacing.large} auto 0;
  font-size: ${typography.textSmall};
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: #999;
  ${Media.lessThan(Media.small)} {
    margin-top: ${spacing.small};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 268px;
  }
`

export const TreasureContent = styled(TreasureSection)`
  padding: 86px 0 94px 0;
  ${Media.lessThan(Media.small)} {
    height: unset;
    padding-top: 40px;
  }
`

export const RenderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1400px;
  ${hideSMDown};
`
export const ChestIcon = styled.div`
  width: 584px;
  height: 655px;
`
export const ChestIntroduction = styled.div`
  height: 655px;
  width: 700px;
`
export const IntroductionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 307px;
  height: 56px;
  color: #fff;
  font-size: 60px;
`
export const IntroductionSubTitle = styled.div`
  width: 307px;
  height: 56px;
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 24px;
`
export const LittleTitle = styled.div`
  width: 32px;
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ff8b00;
  line-height: 31px;
`
export const IntroductionTip = styled.div`
  width: 420px;
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
`

export const IntroductionContent = styled.div`
  width: 574px;
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #13cb75;
  line-height: 30px;
`

export const IntroductionSubTip = styled.p`
  width: 508px;
  height: 14px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #bbbbbb;
`

export const MobileWrapper = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: flex;
  }
`
