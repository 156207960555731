import React from 'react'
import {
  RenderContainer,
  ChestIcon,
  ChestIntroduction,
  IntroductionTitle,
  IntroductionSubTitle,
  LittleTitle,
  IntroductionTip,
  IntroductionContent,
  ContentIcon,
  CenterContent,
  BackgroundBanner,
  ChestIconMb,
} from './atom'
import instroduct from './icons/instroduction.png'
import instroductMb from './icons/instroduction-mb.png'

export default class Instroduction extends React.PureComponent {
  render() {
    return (
      <>
        <RenderContainer>
          <ChestIcon>
            <img src={instroduct} alt='' />
          </ChestIcon>
          <BackgroundBanner />
          <ChestIntroduction>
            <IntroductionTitle>
              <IntroductionSubTitle>瑞兔百宝箱</IntroductionSubTitle>
              <LittleTitle>免费</LittleTitle>
            </IntroductionTitle>
            <IntroductionTip>
              瑞兔百宝箱（英文：Raytool）为瑞云自主开发的一款为提升CG艺术家工作效率的百宝工具箱
            </IntroductionTip>
            <IntroductionContent>
              <CenterContent>
                <ContentIcon />
                <p>内含多种功能，可大幅减少用户在文件制作过程中因不规范操作而引起的错误出现</p>
              </CenterContent>
              <CenterContent>
                <ContentIcon />
                <p>让用户在无感中制作出更规范的制作文件，有效降低渲染时长</p>
              </CenterContent>
              <CenterContent>
                <ContentIcon />
                <p>简化制作流程的同时让艺术家将更多时间和精力集中到创作上，高效完成项目</p>
              </CenterContent>
            </IntroductionContent>
          </ChestIntroduction>
          <ChestIconMb>
            <img src={instroductMb} alt='' />
          </ChestIconMb>
        </RenderContainer>
      </>
    )
  }
}
